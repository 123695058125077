import React, { useState, useEffect } from "react";
import Data from "./Data";
import "./Home.css";
import Slide from "./Slide";

const Home = () => {
  const [sectionLoaded, setSectionLoaded] = useState(false);

  useEffect(() => {
    setSectionLoaded(true);
  }, []);

  return (
    <>
      <link rel="preload" href="images/slider-1.jpg" as="image" />
      <section
        className={`animate-section ${
          sectionLoaded ? "animate-section-active" : ""
        }`}
      >
        <Slide slides={Data} />
      </section>
    </>
  );
};

export default Home;
