import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import "../popular/MostPopular.css";
import "./Works.css";
import Card from "./Card";
import Wdata from "./Wdata";

const Works = () => {
  const [sectionLoaded, setSectionLoaded] = useState(false);

  useEffect(() => {
    setSectionLoaded(true);
  }, []);

  const fadeIn = useSpring({
    opacity: sectionLoaded ? 1 : 0,
    transform: sectionLoaded ? "translateY(0)" : "translateY(50px)",
  });

  return (
    <section>
      <div className="popular works">
        <div className="container">
          <animated.div style={fadeIn}>
            <div className="heading">
              <h1>Why partner with us? </h1>
              <div className="line"></div>
            </div>

            <div className="content grid">
              {Wdata.map((value, index) => {
                return (
                  <Card
                    key={index}
                    cover={value.cover}
                    title={value.title}
                    desc={value.desc}
                  />
                );
              })}
            </div>
          </animated.div>
        </div>
      </div>
    </section>
  );
};

export default Works;
