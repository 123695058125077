import React, { useState } from "react";
import "./About.css";
import { Link } from 'react-router-dom';
import WhatsApp from "../HomeSection/Whatsapp";
const AboutCard = () => {
  const [currentVideo, setCurrentVideo] = useState(0);

  const videoSources = [
    "/images/about-img-1.mp4",
    "/images/about-img-2.mp4",
    "/images/about-img-3.mp4",
  ];

  const handleVideoChange = () => {
    setCurrentVideo((currentVideo + 1) % videoSources.length);
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className="aboutCard mtop flex_space">
        <div className="row row1 animate__animated  animate__zoomInRight" >
          <h4>About The Heights</h4>
          <h1>
            Your Gateway to <span>Unforgettable Adventures</span>
          </h1>
          <p>
            At The Heights, we believe that travel is the ultimate way to enrich
            your life, and we're dedicated to helping you create unforgettable
            memories. Based in the stunning region of Kashmir, we offer a range
            of tour and travel packages that showcase the best this beautiful
            area has to offer.
          </p>
          <p>
            Our team is passionate about creating personalized experiences that
            cater to your individual needs and interests. Whether you're looking
            for a relaxing getaway or an action-packed adventure, we have got
            you covered. We work closely with top hotels and local businesses to
            provide you with the best accommodations, dining, and activities,
            all while ensuring your safety and comfort.
          </p>
          <Link to="/abouttheheights" aria-label="Learn more about The Heights" className="secondary-btn" onClick={handleLinkClick}>
            Read More <i className="fas fa-long-arrow-alt-right"></i>
          </Link>
        </div>
        <div className="row image roww animate__animated  animate__zoomInLeft" onClick={handleVideoChange}>
          <video autoPlay muted 
            src={videoSources[currentVideo]}
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <WhatsApp/>
      </div>
    </>
  );
};

export default AboutCard;
