import React from "react";
import "../popular/MostPopular.css";
//import Dcards from "../../Destinations/Dcards"
import AllItem from "../../Destinations/AllItem";

const DestinationHome = () => {
  return (
    <>
      <section className="popular top">
        <div className="full_container">
          <div className="heading">
            <h1>
              "Elevate Your Journey with The Heights" <br /> Luxurious
              Accommodations, Tailored Tours, and Thrilling Adventures Await{" "}
            </h1>
            {/* <p>
              We partner with hotels, resorts, and cottages to offer exclusive
              benefits to our clients and members, such as discounted rates and
              access to facilities. This collaboration helps promote the
              accommodations and enhance our members' travel experience.
            </p> */}
            <div className="line"></div>
          </div>

          <div className="content">
            <AllItem />
          </div>
        </div>
      </section>
    </>
  );
};

export default DestinationHome;
