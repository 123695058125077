import React, { useState } from "react";
import "./pack.css";
const Summar = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPack, setShowPack] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
    setIsClicked(!isClicked);
  };

  const winterPack = () => {
    setShowPack(!showPack);
    setIsClicked(!isClicked);
  };

  return (
    <div className="packages">
      {isClicked ? (
        <h1 onClick={handleClick}>
          Summer Season Package  (6N 7D)
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </h1>
      ) : (
        <h1 onClick={handleClick}>
          Summer Season Package  (6N 7D)
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </h1>
      )}
      {showDetails && (
        <div>
          <h2>Inclusions </h2>
          <ul>
            <li>Accomodation</li>
            <li>Breakfast & Dinner</li>
            <li>Srinagar Local Sightseeing</li>
            <li>Shikara Ride for 01 Hour</li>
            <li>Pick up & Drop at Srinagar Airport</li>
          </ul>
          <h2>Exclusions </h2>
          <ul>
            <li>Air Tickets / Train Tickets</li>
            <li>Pony Ride / Sledging</li>
            <li>Entry fee at all places & gardens</li>
            <li>Local Cabs for Sightseeing (Sonmarg, Gulmarg, Pahalgam)</li>
            <li>Gandola Ride Tickets</li>
            <li>Shopping</li>
          </ul>
          {isClicked ? (
            <h3  id="packdet" onClick={winterPack}>
              Package Details
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </h3>
          ) : (
            <h3  id="packdet" onClick={winterPack}>
              Package Details
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </h3>
          )}
          {showPack && (
            <>
              <h3>Day 1: Arrival in Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                Welcome to Srinagar, Kashmir! Upon your arrival at Srinagar
                Airport, our representative will be there to receive you. You
                will be transferred to your hotel, where you can relax and
                unwind. In the afternoon, visit the world-famous Mughal Gardens,
                including Nishat Garden, Shalimar Garden, and Chashme Shahi.
                These gardens are known for their exquisite beauty, manicured
                lawns, vibrant flowers, and charming fountains. Afterward,
                return to your hotel for a delicious dinner and comfortable
                overnight stay.{" "}
              </p>
              <h3>Day 2: Srinagar - Sonamarg - Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                After breakfast, embark on a full-day excursion to Sonamarg,
                also known as the "Meadow of Gold." The journey to Sonamarg
                offers breathtaking views of pine trees and green meadows. Along
                the way, you'll pass by the Nilagrad River and Sind River. In
                Sonamarg, you have the option to take a pony ride (at your own
                expense) to the Thajiwas glacier, where you can experience
                walking on snow throughout the year. In the evening, return to
                your hotel in Srinagar for dinner and overnight stay.{" "}
              </p>
              <h3>Day 3: Srinagar - Doodhpathri - Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                After breakfast, embark on a full-day excursion to Doodhpathri,
                also known as the "Valley of Milk." The journey takes you
                through scenic roads lined with pine trees and green meadows.
                Doodhpathri is a serene and picturesque destination, offering
                natural beauty and a peaceful ambiance. Enjoy the tranquility of
                the valley and immerse yourself in its charm. In the evening,
                return to your hotel in Srinagar for dinner and overnight stay.{" "}
              </p>
              <h3>Day 4: Srinagar - Gulmarg</h3>
              <h4>Day Description</h4>
              <p>
                After a hearty breakfast, travel from Srinagar to Gulmarg. Upon
                arrival, complete the check-in process at your hotel. Gulmarg,
                also known as the "Meadow of Flowers," offers stunning
                surroundings and a renowned 18-hole Golf Course. One of the
                highlights of Gulmarg is the Gulmarg Gondola, the world's
                highest and Asia's longest cable car ride. Enjoy photography,
                horse riding, and shopping at this beautiful snow point. Return
                to your hotel in Gulmarg for dinner and an overnight stay.{" "}
              </p>
              <h3>Day 5: Gulmarg - Pahalgam</h3>
              <h4>Day Description</h4>
              <p>
                After breakfast, embark on a road trip from Gulmarg to Pahalgam.
                Pahalgam, also known as the "Valley of Shepherds," is situated
                at an altitude of 2440 meters above sea level. En route, visit
                saffron fields and soak in the natural beauty of the valley.
                Pahalgam is a favorite location for the Indian film industry and
                offers attractions such as Betaab Valley, Chandanwari, and Aru
                Valley (additional cost). Explore the captivating landscapes of
                Pahalgam and return to your hotel for dinner and overnight stay.{" "}
              </p>
              <h3>Day 6: Pahalgam - Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                After breakfast, travel from Pahalgam to Srinagar. Upon arrival,
                complete the check-in process at your hotel. In the afternoon,
                embark on a 1-hour shikara ride on the famous Dal Lake.
                Experience the tranquility of the lake and enjoy the scenic
                beauty of the surroundings. After the ride, return to your
                houseboat for dinner and a comfortable overnight stay.{" "}
              </p>
              <h3>Day 7: Departure from Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                After a healthy breakfast at your hotel, it's time to say
                goodbye to the enchanting land of Kashmir. Our representative
                will accompany you to Srinagar Airport for your departure,
                ensuring a smooth and hassle-free journey. Take with you
                beautiful memories and the desire to return to this captivating
                destination. Farewell and safe travels!{" "}
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Summar;
