import React, { useState } from "react";
import "../Packages/pack.css";
const Naranag = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPack, setShowPack] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
    setIsClicked(!isClicked);
  };

  const winterPack = () => {
    setShowPack(!showPack);
    setIsClicked(!isClicked);
  };

  return (
    <div className="packages">
      {isClicked ? (
        <h1 onClick={handleClick}>
          Sonmarg Vishansar Naranag Trek, Kashmir
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </h1>
      ) : (
        <h1 onClick={handleClick}>
          Sonmarg Vishansar Naranag Trek, Kashmir
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </h1>
      )}
      {showDetails && (
        <div>
          <div className="images">
            <img src="/images/sonmarg-vishansar-naranag-trek (1).jpg" alt="" />
          </div>

          <h2>Inclusions Whats' included</h2>
          <ul>
            <li>Accomodation</li>
            <li>Transferr</li>
            <li>Meals</li>
            <li>Sightseeing</li>
          </ul>

          {isClicked ? (
            <h3 onClick={winterPack}>
              Sonmarg Vishansar Naranag Trek (Detailed Itinerary)
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </h3>
          ) : (
            <h3 onClick={winterPack}>
              Sonmarg Vishansar Naranag Trek (Detailed Itinerary)
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </h3>
          )}
          {showPack && (
            <>
              <h3>Day - 1 Arrival </h3>
              <h4>Day Description</h4>
              <p>
                Upon arrival at Srinagar, meet our representative at the
                airport. Transfer to the houseboat. While travelling from
                airport to houseboat you will get to see the scenic views of
                Srinagar. Overnight stay in houseboats.
              </p>

              <h3>
                Day - 2 Srinagar - Sonamarg - Nichnai 85+9 Km (by car/trek)
              </h3>
              <h4>Day Description</h4>
              <p>
                Today early morning we will leave from Srinagar by car and drive
                along Sind River. Arrive, in Sonamarg. The trekking will begin
                from Shitkari which is 2 km ahead of Sonamarg. The trek is steep
                and steady and passes through the meadows of Shokadri. Reach
                Nichnai and camp for overnight stay.
              </p>

              <h3>Day - 3 Nichnai - Vishnasar - Krishansar Lake 15 Km.</h3>
              <h4>Day Description</h4>
              <p>
                Today we will start ascending to Nichnai pass (above 4000 mt)
                and then will start descending towards Vishnasar and Krishnasar
                Lake. We need to make 5/6 hours walk through the scenic meadows
                that are sprawling with wild flowers. Crossing a stream at the
                end we will pitch the tent. Overnight in Tents.
              </p>

              <h3>Day - 4 Krishansar - Gadsar 12 Km.</h3>
              <h4>Day Description</h4>
              <p>
                Today we will start early for Gadsar lake which is known as the
                lake of flowers. We will pass through Krishansar Lake and will
                ascend the steep height of the Gadsar pass (4080 mtrs). Later we
                will descend through Yamsar lake to Gadsar. Overnight in Tents.
              </p>

              <h3>Day - 5 Gadsar - Satsar Lakes 16 Km.</h3>
              <h4>Day Description</h4>
              <p>
                From Gadsar to Poshpatri (The valley of flowers) there is an
                easy ascending followed by a gradual walk up to Megandub, Dupta
                Pani. Walk for a little more and we will set camp at the base of
                Zagibal Mountain. Overnight in Tents.
              </p>

              <h3>Day - 6 Satsar - Gangabal Lake 8 Km.</h3>
              <h4>Day Description</h4>
              <p>
                Ascend Zajibal Pass (4180 mt) and cross a small glacier top.
                This is the highest pass of this trek. All your exhaustion will
                vanish once you reach the top and you see Mount Haramukh
                standing at your face. On the base of this hill you will spot
                the twin beautiful lakes of Gangabal and Nundkol. Descend to
                Gangabal. Overnight in Tents.
              </p>

              <h3>Day - 7 Gangabal – Naranag - Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                Today we will walk till Naranag. Start from Gangabal and trek
                through the meadows of Bodpathri, Trunkol and descending through
                the pine wood you will reach Naranag. Here we will board our car
                and within around 2 hours we will be in Srinagar. Overnight stay
                in the houseboat.
              </p>

              <h3>Day - 8 Srinagar Departure</h3>
              <h4>Day Description</h4>
              <p>
                Today we will leave from Srinagar. Before that if you have time
                you can go for Srinagar city tour. You will be dropped at
                airport by our representative.
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Naranag;
