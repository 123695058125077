import React from "react";
import "../Packages/pack.css";
import Gangbal from "./Gangbal";
import Tarsar from "./Tarsar";
import Naranag from "./Naranag ";
import Chattargul from "./Chattargul ";
import Marsar from "./Marsar";
import Kolahoi from "./Kolahoi ";
const Homeadven = () => {
  return (
    <>
      <div className="packHome">
        <h1 id="pack">Package</h1>

        <Gangbal />
        <Tarsar />
        <Naranag />
        <Chattargul />
        <Marsar />
        <Kolahoi />
      </div>
    </>
  );
};
export default Homeadven;
