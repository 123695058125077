const Hdata = [
  {
    id: 1,
    title: "Hotel Fifth Season",
    image: "/images/fifthseason.jpg",
    website: "https://hotelfifthseason.in/",
    brotitle: "Download Catalog",
    youlink: "https://youtu.be/XoFeCX6Gt4A",
    brochureLink:
      "https://drive.google.com/file/d/14aSMEBeTh9F_sklshxknqjbATDku9aXM/view",
  },
  {
    id: 2,
    title: "Hotel Hills Heaven",
    image: "/images/hills.png",
    website: "https://www.hotelhillsheaven.com",
    brotitle: "Download Catalog",
    youlink: "https://youtu.be/ijTRlwI83os",
    brochureLink:
      "https://drive.google.com/file/d/14wPyYc0BGVhCkgKGGNCG6hzYaNY1Spbv/view?usp=sharing",
  },
  {
    id: 3,
    title: "Shabnam Resort",
    image: "/images/shabnam.jpg",
    website: "https://shabnamresort.com/",
    brotitle: "Download Catalog",
    youlink: "https://youtu.be/CsKtPySCJyg",
    brochureLink:
      "https://drive.google.com/file/d/14oKPVJqTIV63vwxdI2RHqzz_yyqKa4BV/view?usp=sharing",
  },
  {
    id: 4,
    title: "Hotel Shanu",
    image: "/images/hispeed.jpg",
    website: "https://hotelshanu.com/",
    brotitle: "Download Catalog",
    youlink: "https://www.youtube.com/@officiatheheights",
    brochureLink:
      "https://drive.google.com/file/d/1_5M1U2RNK_jAFC88nSBmVcE4hEpkwoQT/view",
  },
  {
    id: 5,
    title: "Imus HomeStay",
    image: "/images/imus.jpg",
    website: "https://imushomestay.com/",
    brotitle: "Download Catalog",
    youlink: "https://www.youtube.com/@officiatheheights",
    brochureLink:
      "https://drive.google.com/file/d/14pMpJfP_H_uudVvsF4duN97zZHhffZgO/view?usp=sharing",
  },
  {
    id: 6,
    title: "Waterfront Pine",
    image: "/images/water.jpg",
    website: "https://www.waterfrontpine.com",
    brotitle: "Download Catalog",
    youlink: "https://youtu.be/Im_vzYA-UOU",
    brochureLink:
      "https://drive.google.com/file/d/14kkG1XHkb2MdjMjLpLg4WaVwTNX61he7/view?usp=sharing",
  },
  {
    id: 7,
    title: "Kashmir Holiday Inn",
    image: "/images/kashmirholidayinn.jpg",
    website: "https://kashmirholidayinn.com/",
    brotitle: "Download Catalog",
    youlink: "https://www.youtube.com/@officiatheheights",
    brochureLink:
      "https://drive.google.com/file/d/153ukJuBbfmV5Sksq024YG6tY5ZSmoqet/view?usp=sharing",
  },
  {
    id: 8,
    title: "The Royal Choice",
    image: "/images/royalchoice.jpg",
    website: "https://www.theroyalchoice.in",
    brotitle: "Download Catalog",
    youlink: "https://www.youtube.com/@officiatheheights",
    brochureLink:
      "https://drive.google.com/file/d/1HAIhSAqfwI2tdCAcl3rd7z6EYtYVkTjW/view?usp=sharing",
  },
  {
    id: 9,
    title: "Hotel Greenz",
    image: "/images/hotelgreenz.jpg",
    website: "https://www.hotelgreenz.com",
    brotitle: "Download Catalog",
    youlink: "https://www.youtube.com/@officiatheheights",
    brochureLink:
      "https://drive.google.com/file/d/1HM3BULcPJUlUG5Enc2KQwqazDbvLRMmb/view?usp=sharing",
  },
  {
    id: 10,
    title: "Akbar Residency",
    image: "/images/akbar.jpeg",
    website: "https://www.akbarresidency.com",
    brotitle: "Download Catalog",
    youlink: "https://www.youtube.com/@officiatheheights",
    brochureLink:
      "https://drive.google.com/file/d/16toehhOKNumSKyarvtb9FczPBhFuIYzQ/view?usp=sharing",
  },
  {
    id: 11,
    title: "Taj Inn",
    image: "/images/tajinn.jpg",
    website: "https://www.tajinn.com",
    brotitle: "Download Catalog",
    youlink: "https://www.youtube.com/@officiatheheights",
    brochureLink:
      "https://drive.google.com/file/d/1OctEMT99bKQstyL3WRe3l5R1u5kgyrCu/view?usp=sharing",
  },
  {
    id: 12,
    title: "Nature's Edge",
    image: "/images/edge.jpeg",
    website: "https://thenaturesedge.in/",
    brotitle: "Download Catalog",
    youlink: "https://www.youtube.com/@officiatheheights",
    brochureLink:
      "https://drive.google.com/file/d/1QFL6F7RlxtXlAT8i8DSET5_kq44pRLAG/view",
  },
];

export default Hdata;
