import React, { useState } from "react";
import "./pack.css";
const Summar = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPack, setShowPack] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
    setIsClicked(!isClicked);
  };

  const winterPack = () => {
    setShowPack(!showPack);
    setIsClicked(!isClicked);
  };

  return (
    <div className="packages">
      {isClicked ? (
        <h1 onClick={handleClick}>
          Autumn Season Package (4N 5D)
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </h1>
      ) : (
        <h1 onClick={handleClick}>
          Autumn Season Package (4N 5D)
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </h1>
      )}
      {showDetails && (
        <div>
          <h2>Inclusions </h2>
          <ul>
            <li>Accomodation</li>
            <li>Breakfast & Dinner</li>
            <li>Srinagar Local Sightseeing</li>
            <li>Shikara Ride for 01 Hour</li>
            <li>Pick up & Drop at Srinagar Airport</li>
          </ul>
          <h2>Exclusions </h2>
          <ul>
            <li>Air Tickets / Train Tickets</li>
            <li>Pony Ride / Sledging</li>
            <li>Entry fee at all places & gardens</li>
            <li>Local Cabs for Sightseeing (Sonmarg, Gulmarg, Pahalgam)</li>
            <li>Gandola Ride Tickets</li>
            <li>Shopping</li>
          </ul>
          {isClicked ? (
            <h3 id="packdet" onClick={winterPack}>
              Package Details
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </h3>
          ) : (
            <h3  id="packdet" onClick={winterPack}>
              Package Details
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </h3>
          )}
          {showPack && (
            <>
              <h3>Day 1: Arrival in Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                Welcome to Srinagar, Kashmir! Upon your arrival at Srinagar
                Airport, our representative will be there to receive you. After
                a warm welcome, you will be transferred to your hotel for
                check-in. Take some time to relax and acclimate to the beautiful
                surroundings. In the afternoon, embark on a visit to the
                world-famous Mughal Gardens, including Nishat Garden, Shalimar
                Garden, and Chashme Shahi. These gardens showcase exquisite
                Mughal architecture, stunning landscapes, and serene water
                features. After exploring the gardens, return to your hotel for
                an overnight stay in Srinagar.{" "}
              </p>
              <h3>Day 2: Srinagar - Sonamarg - Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                Start your day with a hearty breakfast at the hotel, and then
                proceed on a full-day excursion to Sonamarg, also known as the
                "Meadow of Gold." Enjoy a scenic drive along roads lined with
                majestic pine trees and lush green meadows. Admire the beauty of
                the Nilagrad River and Sind River that accompany you along the
                way. Upon reaching Sonamarg, you have the option to take a pony
                ride (at your own expense) to the enchanting Thajiwas glacier,
                where you can witness snow throughout the year. In the evening,
                return to your hotel in Srinagar for an overnight stay.
              </p>
              <h3>Day 3: Srinagar - Gulmarg</h3>
              <h4>Day Description</h4>
              <p>
                After a delicious breakfast, you will be transferred from
                Srinagar to Gulmarg. Situated at an elevation of approximately
                2730 meters above sea level, Gulmarg is known as the "Meadow of
                Flowers." It is a picturesque town and a popular skiing
                destination during winters. One of the main attractions of
                Gulmarg is the "Gulmarg Gondola," one of the highest cable cars
                in the world (additional charges apply). Enjoy the breathtaking
                views and explore the beauty of Gulmarg. After sightseeing,
                return to your hotel for an overnight stay.
              </p>
              <h3>Day 4: Srinagar - Pahalgam - Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                After breakfast, embark on a road trip from Srinagar to
                Pahalgam. Pahalgam, also known as the "Valley of Shepherds," is
                located at an altitude of 2440 meters above sea level. En route,
                visit the saffron fields and immerse yourself in the scenic
                beauty of the valley. Pahalgam is renowned for its natural charm
                and is a favorite destination for the Indian film industry. You
                can enjoy various activities and visit attractions such as
                Betaab Valley, Chandanwari, and Aru Valley (additional charges
                apply). After a day of sightseeing, return to your houseboat in
                Srinagar for an overnight stay.{" "}
              </p>
              <h3>Day 5: Departure from Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                Enjoy a healthy breakfast at your hotel and cherish the final
                moments of your Kashmir tour. Our representative will accompany
                you to Srinagar Airport for your departure. Bid farewell to the
                breathtaking beauty of Kashmir and take your onward journey back
                home.
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Summar;
