import React, { useState } from "react";
import "./pack.css";
const Winter = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPack, setShowPack] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
    setIsClicked(!isClicked);
  };

  const winterPack = () => {
    setShowPack(!showPack);
    setIsClicked(!isClicked);
  };

  return (
    <div className="packages">
      {isClicked ? (
        <h1 onClick={handleClick}>
          Winter Season Package  (7N 8D)
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </h1>
      ) : (
        <h1 onClick={handleClick}>
          Winter Season Package  (7N 8D)
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </h1>
      )}
      {showDetails && (
        <div>
          <h2>Inclusions </h2>
          <ul>
            <li>Accomodation</li>
            <li>Breakfast & Dinner</li>
            <li>Srinagar Local Sightseeing</li>
            <li>Shikara Ride for 01 Hour</li>
            <li>Pick up & Drop at Srinagar Airport</li>
          </ul>
          <h2>Exclusions </h2>
          <ul>
            <li>Air Tickets / Train Tickets</li>
            <li>Pony Ride / Sledging</li>
            <li>Entry fee at all places & gardens</li>
            <li>Local Cabs for Sightseeing (Sonmarg, Gulmarg, Pahalgam)</li>
            <li>Gandola Ride Tickets</li>
            <li>Shopping</li>
          </ul>
          {isClicked ? (
            <h3 id="packdet" onClick={winterPack}>
              Package Details
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </h3>
          ) : (
            <h3 id="packdet" onClick={winterPack}>
              Package Details
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </h3>
          )}
          {showPack && (
            <>
              <h3>Day 1: Arrival and Srinagar Local</h3>
              <h4>Day Description</h4>
              <p>
                Welcome to Srinagar, Kashmir. Upon your arrival at Srinagar
                Airport, our representative will be there to greet you and
                assist you with the transfer to your hotel. Take some time to
                relax and unwind before starting your exploration of this
                enchanting city. Later, embark on a visit to the world-famous
                Mughal gardens including Nishat, Shalimar, Harvan, and Chashme
                Shahi. Marvel at the meticulously manicured lawns, vibrant
                flowers, and beautiful fountains that adorn these stunning
                gardens. After a day filled with natural beauty and serenity,
                return to your hotel in Srinagar for an overnight stay.
              </p>
              <h3>Day 2: Srinagar-Sonmarg (Day trip)</h3>
              <h4>Day Description</h4>
              <p>
                After enjoying a delicious breakfast at the hotel, get ready for
                a mesmerizing day trip to Sonamarg, also known as the "Meadow of
                Gold." The journey to Sonamarg is a treat in itself, as you
                drive along scenic roads adorned with pine trees and lush green
                meadows. Admire the picturesque Nilagard River and Sind River
                that accompany you along the way. On reaching Sonamarg, you have
                the option to take a pony ride (at your own expense) to the
                Thajiwas glacier, where you can experience the joy of walking on
                snow throughout the year. Return to your hotel in Srinagar for
                an overnight stay, carrying beautiful memories of Sonamarg.
              </p>
              <h3>Day 3: Srinagar-Doodhpathri</h3>
              <h4>Day Description</h4>
              <p>
                Today, you will be transferred from Srinagar to Doodhpathri,
                also known as the "Valley of Milk." The journey takes you
                through scenic roads surrounded by majestic pine trees and lush
                green meadows. Doodhpathri, located approximately 40 kilometers
                from Srinagar, is a tranquil and picturesque destination,
                offering immense natural beauty and a peaceful ambiance. Spend a
                delightful day exploring the enchanting landscapes of
                Doodhpathri before returning to your hotel for an overnight
                stay.
              </p>
              <h3>Day 4: Doodhpathri-Gulmarg</h3>
              <h4>Day Description</h4>
              <p>
                After relishing a delicious breakfast, embark on a journey to
                Gulmarg, situated at an altitude of approximately 2730 meters
                above sea level. Known as the "Meadow of Flowers," Gulmarg is a
                breathtakingly beautiful town and a popular skiing destination
                during winters. One of the highlights of Gulmarg is the "Gulmarg
                Gondola," one of the highest cable cars in the world (additional
                cost). Explore the scenic wonders of Gulmarg and immerse
                yourself in its natural splendor. After a day of sightseeing,
                return to your hotel for a comfortable overnight stay.
              </p>
              <h3>Day 5: Gulmarg-Pahalgam</h3>
              <h4>Day Description</h4>
              <p>
                Today, prepare for a road trip from Gulmarg to Pahalgam, so
                ensure your luggage is packed and enjoy an early morning
                breakfast. Pahalgam, also known as the "Valley of Shepherds," is
                situated at an altitude of 2440 meters above sea level. En
                route, visit the scenic saffron fields and immerse yourself in
                the natural beauty of the valley. Pahalgam is renowned for its
                captivating landscapes and is a favorite location for the Indian
                film industry. Explore attractions such as Betaab Valley,
                Chandanwari, and Aru Valley (additional cost) while enjoying the
                charm of the valley. After a day filled with exploration, return
                to your hotel in Pahalgam for a comfortable overnight stay.
              </p>
              <h3>Day 6: Pahalgam Local</h3>
              <h4>Day Description</h4>
              <p>
                Start your day with a delicious breakfast at the hotel and
                prepare to explore the captivating beauty of Pahalgam. Visit
                attractions like Betaab Valley, Chandanwari, and Aru Valley
                (additional cost) and immerse yourself in the serene
                surroundings of this picturesque valley. Experience the
                tranquility and natural wonders that Pahalgam has to offer.
                After a day of sightseeing, return to your hotel in Pahalgam for
                a relaxing overnight stay.
              </p>
              <h3>Day 7: Pahalgam-Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                Today, embark on another scenic road trip from Pahalgam to
                Srinagar after enjoying a hearty breakfast at the hotel. Upon
                your arrival in Srinagar, complete the check-in process at your
                hotel. Later, embark on a delightful shikara ride, gliding
                through the serene waters of the famous Dal Lake, surrounded by
                breathtaking views of the mountains. After the ride, head back
                to your houseboat for a comfortable overnight stay, experiencing
                the unique charm of staying on the lake.
              </p>
              <h3>Day 8: Departure</h3>
              <h4>Day Description</h4>
              <p>
                As your memorable Kashmir tour comes to an end, savor a healthy
                breakfast at your hotel. Our representative will accompany you
                to Srinagar Airport for your departure, ensuring a smooth and
                hassle-free journey. Take with you beautiful memories and the
                desire to return to this enchanting land. Farewell and safe
                travels!
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Winter;
