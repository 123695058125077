import React from "react";
import "./HeadTitle.css";
import { useLocation, Link } from "react-router-dom";

const HeadTitle = () => {
  const location = useLocation();

  return (
    <>
      <section className="image-heading animate__animated ">
        <div className="container animate__animated animate__lightSpeedInRight">
          {/*<h1>{location.pathname.slice(1, -2, -1)}</h1>*/}
          <h1>
            {location.pathname.includes("singlepage/2")
              ? "Tour and Travels"
              : location.pathname.includes("singlepage/3")
              ? "Adventure"
              : location.pathname.includes("singlepage")
              ? "Hotels"
              : location.pathname.includes("services")
              ? "Services"
              : location.pathname.includes("contact")
              ? "Contact us"
              : location.pathname.includes("about")
              ? "About us"
              : location.pathname.includes("testimonial")
              ? "Testimonial"
              : location.pathname.includes("team")
              ? "Team"
              : "Tourandtravel"}
          </h1>

          {/*<h1>{location.pathname.replace(/ /, "")}</h1>*/}

          <button>
            <Link to="/"> Home / </Link>
            <span>
              {" "}
              {location.pathname.includes("singlepage/2")
                ? "Tour and Travels"
                : location.pathname.includes("singlepage/3")
                ? "Adventure"
                : location.pathname.includes("singlepage")
                ? "Hotels"
                : location.pathname.includes("services")
                ? "Services"
                : location.pathname.includes("contact")
                ? "Contact us"
                : location.pathname.includes("about")
                ? "About us"
                : location.pathname.includes("testimonial")
                ? "Testimonial"
                : location.pathname.includes("team")
                ? "Team"
                : "Tourandtravel"}
            </span>
          </button>
        </div>
      </section>
    </>
  );
};

export default HeadTitle;
