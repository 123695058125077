import React from "react";

const HCards = ({
  item: { id, image, title, brotitle, brochureLink, youlink },
  website,
}) => {
  const handleClick = () => {
    window.open(website, "_blank"); // open website in new tab
  };

  const handleBrochureClick = (event) => {
    event.stopPropagation(); // prevent the click event from bubbling up to the parent div
    window.open(brochureLink, "_blank"); // open the brochure link in a new tab
  };
  // const handleTourClick = () => {
  //   window.open(youlink, "_blank"); // open website in new tab
  // };
  return (
    <div className="items bro animate__animated animate__jackInTheBox">
      <div className="img">
        <img src={image} alt="" />
        <i onClick={handleClick} className="fas fa-external-link-alt"></i>
      </div>
      <div className="title" onClick={handleClick}>
        <h3>{title}</h3>
      </div>
      <div className="bro-title" onClick={handleBrochureClick}>
        <h3>{brotitle}</h3>
      </div>
      {/* <div className="title" onClick={handleTourClick}>
        <h3>Watch Tour</h3>
      </div> */}
    </div>
  );
};

export default HCards;
