const Data = [
  {
    image: "images/slider-1.jpg",
  },
  {
    image: "images/hispeed.jpg",
  },
  {
    image: "images/fifthseason.jpg",
  },
  {
    image: "images/water.jpg",
  },
  {
    image: "images/hills.png",
  },
  {
    image: "images/shabnam.jpg",
  },
  {
    image: "images/kashmirholidayinn.jpg",
  },
];
export default Data;
