import React from "react"
import HeadTitle from "../../Common/HeadTitle/HeadTitle"
import AllItem from "./AllItem"
import WhatsApp from "../HomeSection/Whatsapp";


const Destinations = () => {
  return (
    <div>
      <HeadTitle />
      <AllItem />
      <WhatsApp/>
    </div>
  )
}

export default Destinations
