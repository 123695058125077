import React, { useState } from "react";
import "../Packages/pack.css";
const Kolahoi = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPack, setShowPack] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
    setIsClicked(!isClicked);
  };

  const winterPack = () => {
    setShowPack(!showPack);
    setIsClicked(!isClicked);
  };

  return (
    <div className="packages">
      {isClicked ? (
        <h1 onClick={handleClick}>
          Kolahoi Base Camp Trek
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </h1>
      ) : (
        <h1 onClick={handleClick}>
          Kolahoi Base Camp Trek
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </h1>
      )}
      {showDetails && (
        <div>
          <div className="images">
            <img src="/images/kolahoi-base-camp-trek.jpg" alt="" />
          </div>

          <h2>Inclusions Whats' included</h2>
          <ul>
            <li>Accomodation</li>
            <li>Transferr</li>
            <li>Meals</li>
            <li>Sightseeing</li>
          </ul>

          {isClicked ? (
            <h3 onClick={winterPack}>
              Kolahoi Base Camp Trek (Detailed Itinerary)
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </h3>
          ) : (
            <h3 onClick={winterPack}>
              Kolahoi Base Camp Trek (Detailed Itinerary)
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </h3>
          )}
          {showPack && (
            <>
              <h3>Day - 1 Arrival </h3>
              <h4>Day Description</h4>
              <p>
                On arrival you will be met at the airport (or the Tourist
                Reception Centre if you travelled overland) and transferred to
                the houseboat/hotel for your overnight stay. There will be an
                afternoon Shikara ride on Dal Lake.
              </p>

              <h3>
                Day - 2 Srinagar - Aru via Pahalgam (2420m). 4 hours approx.
              </h3>
              <h4>Day Description</h4>
              <p>
                An early morning start is imperative as it is a long drive of
                105 km to Aru, a beautiful lush green pasture with the grand
                Katrinag Mountains as a backdrop. Afternoon at leisure, or a few
                short walks to stretch the legs for the days ahead. Overnight in
                tents on a twin share basis.
              </p>

              <h3>Day - 3 Aru - Lidderwat (3050m). 3- 4 Hours, 11 km</h3>
              <h4>Day Description</h4>
              <p>
                Lidder River, crossing the odd side stream. As we near camp the
                valley narrows; a foot bridge leading us to our campsite.
              </p>

              <h3>Day - 4 Lidderwat - Satlanjan (3420m). 2-3 hours</h3>
              <h4>Day Description</h4>
              <p>
                A short and easy day today to help with acclimatization, we trek
                along the true right of the gushing waters of the Lidder River
                on an undulating, occasionally steep, trail. We cross five
                tributaries of a side river beside the Gujars encampment and
                continue with a gentle descent towards a lush green meadow
                beside the Lidder River for our overnight camp. There is an
                optional acclimatization side walk to a ride in the afternoon.
              </p>

              <h3>
                Day - 5 Satlanjan - Kolahoi base camp (4140m) - Lidderwat
                (3050m) 8 hr
              </h3>
              <h4>Day Description</h4>
              <p>
                An early morning start is imperative as we have a long day ahead
                of us. Our hike begins upwards and onwards as we head towards
                Kolahoi Base Camp, passing nomadic shepherds en-route. As we get
                closer to the glacier the walk becomes a little harder as the
                terrain becomes rough glacial moraine, but the increasingly
                spectacular views of the Kolahoi Peak urge us on. In order to
                gain the best view of Kolahoi we may have to climb a little
                higher on the ridge opposite. The return walk is easier and
                quicker as we head back to Lidderwat via Satlanjan, elated from
                being so close to the majestic peak.
              </p>

              <h3>Day - 6 Lidderwat - Srinagar (1585 m )</h3>
              <h4>Day Description</h4>
              <p>
                After a leisurely breakfast we retrace our steps down to Aru and
                board our chartered bus/car to drive to Srinagar for an
                overnight stay at houseboat.
              </p>
              <h3>Day - 7 Departure Day</h3>
              <h4>Day Description</h4>
              <p>
                You will be transferred to Srinagar airport or the Tourist
                Reception Center for overland.
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Kolahoi;
