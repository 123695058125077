const Tdata = [
  {
    id: 1,
    desc: "We went to Kashmir through them and had a very pleasant and fun experience. The hotels in which we stayed were good and offered serene views. Our driver/guide cordially welcomed us and toured us around Kashmir. He was very polite and helpful to my grandmother and otherwise. The people in Kashmir were very kind, warm and welcoming. The food options were nice too! It was a memorable trip indeed!!!"    ,
    name: "Maithilee Mali",
    profile: "/images/profile/female.jpg",
    post: "Traveler",
  },
  {
    id: 2,
    desc: "I know Sahjid through a colleague and he had planned our trip with his team, it was just awesome, the entire trip, the driver the hotels everything was just the best. Sahjid was always available for our calls and I believe when you are on a trip to a place which is cut off from the land for 6 months you really need to be calm and adjusting also. Just go with Heights tour and travels and dont worry about anything.",
    name: "Ankita ",
    profile: "/images/profile/female.jpg",
    post: "Explorer",
  },


  {
    id: 3,
    desc: "Very nice arrangements done. The car provided was very good and comfortable. The hotels were very good. Also whenever we called during the trip we got instant response. They took care of us very nicely throughout the trip.",
    name: "Debargho Ghosh",
    profile: "/images/profile/female.jpg",
    post: "Adventurer",
  },

  {
    id: 4,
    desc: "Amazing experience at this place. Great food, great hosts. A place that feels like home and the staff at the hotel are very welcoming and helpful. They would go way beyond to provide you the best class service. Must stay at this place.",
    name: "Kirti Agrawal",
    profile: "/images/profile/female.jpg",
    post: "Adventurer",
  },
  {
    id: 5,
    desc: "This was my first visit to Kashmir, and they made it truly memorable with their outstanding tour package. Each stay was comfortable and hassle-free, leaving no room for disappointment. I am grateful for the unforgettable experience they provided, which I will cherish forever.", name: "Manas  ",
    profile: "/images/profile/male.jpg",
    post: "Explorer",
  },
  {
    id: 6,
    desc: "All was done with perfection!..places & hotels selected were the indicated ones !..From arrival we felt secure & comfortable with our car driver Azhar who made all efforts in satisfying our tight itinerary!..we fully recommend The Heights Tour and Travels to anyone willing to visit Kashmir.",
    name: "Shubnam",
    profile: "/images/profile/male.jpg",
    post: "Traveler",
  },
];

export default Tdata;
