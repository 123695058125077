import React, { useState } from "react";
import "../Packages/pack.css";
const Marsar = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPack, setShowPack] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
    setIsClicked(!isClicked);
  };

  const winterPack = () => {
    setShowPack(!showPack);
    setIsClicked(!isClicked);
  };

  return (
    <div className="packages">
      {isClicked ? (
        <h1 onClick={handleClick}>
          Tral Narastan Marsar Trek, Kashmir
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </h1>
      ) : (
        <h1 onClick={handleClick}>
          Tral Narastan Marsar Trek, Kashmir
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </h1>
      )}
      {showDetails && (
        <div>
          <div className="images">
            <img src="/images/tral-narastan-marsar-trek.jpg" alt="" />
          </div>

          <h2>Inclusions Whats' included</h2>
          <ul>
            <li>Accomodation</li>
            <li>Transferr</li>
            <li>Meals</li>
            <li>Sightseeing</li>
          </ul>

          {isClicked ? (
            <h3 onClick={winterPack}>
              Tral Narastan Marsar Trek (Detailed Itinerary)
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </h3>
          ) : (
            <h3 onClick={winterPack}>
              Tral Narastan Marsar Trek (Detailed Itinerary)
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </h3>
          )}
          {showPack && (
            <>
              <h3>Day - 1 Arrival </h3>
              <h4>Day Description</h4>
              <p>
                On arrival at Srinagar, you shall meet our representative at the
                airport, who will assist in transferring you to the houseboat.
                Overnight stay at the houseboat.
              </p>

              <h3>Day - 2 Srinagar- Tral- Narastan</h3>
              <h4>Day Description</h4>
              <p>
                Today, we shall be driving to Narastan via the villages of Tral.
                On the same day, we shall start our trek and cross a mountain
                pass (4005m) to reach Dachigam meadows. Overnight stay in the
                tents.
              </p>

              <h3>Day - 3 Narastan-Marsar</h3>
              <h4>Day Description</h4>
              <p>
                We shall start trekking in the morning through the upper meadows
                of Dachigam and reach Marsar Lake. Overnight stay in the tents.
              </p>

              <h3>Day - 4 Marsar - Tarsar</h3>
              <h4>Day Description</h4>
              <p>
                Our trek today will be from Marsar Lake to Tarsar Lake. We shall
                cross another mountain pass (4000 m) to reach Tarsar Lake, which
                is an Almond-shape Lake. Overnight stay in the tents.
              </p>

              <h3>Day - 5 Tarsar - Surfraw</h3>
              <h4>Day Description</h4>
              <p>
                Today, after the breakfast, we will leave for Surfraw from
                Tarsar. We shall cross the 3rd high mountain pass (4000m) to
                reach the upper meadows of Surfraw. Overnight stay here.
              </p>

              <h3>Day - 6 Surfraw - Sumbal - Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                Our trek will be towards Sumbal today. We will be descending
                slowly to Sumbal in the Sind Valley and expect to reach by the
                afternoon. From here, we shall be taking a car ride to Srinagar.
                Overnight stay at houseboat in Srinagar.
              </p>
              <h3>Day - 7 Departure Day</h3>
              <h4>Day Description</h4>
              <p>
                Our representative will assist you to the airport. If there is
                time to spare before the flight, we shall arrange a trip around
                Srinagar.
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Marsar;
