import React from "react";

function Whatsapp() {
  const phoneNumber = "+919622962295"; // replace with your WhatsApp phone number
  const message =
    "Hello! I am interested in your services. Can you please provide me with more information?"; // replace with your default message
  return (
    <>
      {" "}
      <a
        href={`https://wa.me/${phoneNumber}/?text=${encodeURIComponent(
          message
        )}`}
        className="whatsapp-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-whatsapp whatsapp-icon"></i>
      </a>
    </>
  );
}

export default Whatsapp;
