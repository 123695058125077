import React, { useState } from "react";
import "../Packages/pack.css";
const Chattargul = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPack, setShowPack] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
    setIsClicked(!isClicked);
  };

  const winterPack = () => {
    setShowPack(!showPack);
    setIsClicked(!isClicked);
  };

  return (
    <div className="packages">
      {isClicked ? (
        <h1 onClick={handleClick}>
          Chattargul Mahlish Gangabal Trek Kashmir
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </h1>
      ) : (
        <h1 onClick={handleClick}>
          Chattargul Mahlish Gangabal Trek Kashmir
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </h1>
      )}
      {showDetails && (
        <div>
          <div className="images">
            <img
              src="/images/chattargul-mahlish-gangabal-trek (1).jpg"
              alt=""
            />
          </div>

          <h2>Inclusions Whats' included</h2>
          <ul>
            <li>Accomodation</li>
            <li>Transferr</li>
            <li>Meals</li>
            <li>Sightseeing</li>
          </ul>

          {isClicked ? (
            <h3 onClick={winterPack}>
              Chattargul Mahlish Gangabal Trek (Detailed Itinerary)
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </h3>
          ) : (
            <h3 onClick={winterPack}>
              Chattargul Mahlish Gangabal Trek (Detailed Itinerary)
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </h3>
          )}
          {showPack && (
            <>
              <h3>Day - 1 Arrival </h3>
              <h4>Day Description</h4>
              <p>
                On arriving in Srinagar, our representative will help you
                check-in the hotel and then will assist you with the sightseeing
                of Srinagar. Overnight stay in hotel in Srinagar.
              </p>

              <h3>Day - 2 Srinagar – Chattargul – Mahlish (by car 65 kms)</h3>
              <h4>Day Description</h4>
              <p>
                Today, we will drive through the villages of Ganderbal in Sind
                valley to reach Chattargul. On the same day, we shall trek from
                Chatturgul to Mahlish. Overnight stay in Mahlish.
              </p>

              <h3>Day - 3 Mahlish – Kolsar – Gangabal</h3>
              <h4>Day Description</h4>
              <p>
                We shall be walking through the meadows of Mahlish, where we can
                see the alpine flowers. Our trail will be via Kolsar Lake.
                Overnight stay near Gangabal Lake.
              </p>

              <h3>Day - 4 Gangabal Lake - Trunkul</h3>
              <h4>Day Description</h4>
              <p>
                Today, we shall leave for Trunkul from Gangabal. Here, we will
                try our hand in fishing. Overnight stay in Trunkul.
              </p>

              <h3>Day - 5 Trunkhul – Naranag – Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                We will leave for Srinagar today. In the morning, we will be
                trekking through meadows of Bodpathri and Trunkhol and then we
                shall descend to Butsheri to reach Naranag. From Naranag, we
                shall drive two hours to reach Srinagar. Overnight stay at
                houseboat in Srinagar.
              </p>

              <h3>Day - 6 Departure Day</h3>
              <h4>Day Description</h4>
              <p>
                Our representative will assist you to Srinagar airport.
                Depending upon the flight schedule, a short sightseeing trip can
                be organized in Srinagar.
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Chattargul;
