import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleContactClick = () => {
    const phoneNumber = " +919622962295";
    const href = `tel:${phoneNumber}`;
    window.location.href = href;
  };
  const handleMailClick = () => {
    const emailAddress = "booking@theheights.co.in";
    const href = `mailto:${emailAddress}`;
    window.location.href = href;
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 750,
      smooth: "easeInOutQuint",
    });
    closeMobileMenu();
  };

  return (
    <>
      <nav className="navbar animate__animated  animate__fadeInLeft">
        <div className="container flex_space">
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : " fas fa-bars"}></i>
          </div>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li>
              <Link to="/" onClick={scrollToTop}>
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/abouttheheights"
                aria-label="Learn more about The Heights"
                onClick={scrollToTop}
              >
                About us
              </Link>
            </li>

            <li>
              <Link to="/services" onClick={scrollToTop}>
                Services
              </Link>
            </li>

            <li>
              <Link to="/testimonial" onClick={scrollToTop}>
                Testimonial
              </Link>
            </li>

            <li>
              <Link to="/team" onClick={scrollToTop}>
                Team
              </Link>
            </li>
          </ul>

          <div className="login-area flex">
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li>
                <Link to="/contact">
                  <button className="text-dark">Contact Us</button>
                </Link>
              </li>
              <li>
              <a href="https://renderrush.digital.vistaprint.io/s/rFr7xTTlgatZ4oyHWXIjK" className="text-dark vcard">Business Card</a>
              </li>
            </ul>
          </div>
        
        </div>
      </nav>

      <header>
        <div className="container flex_space  " >
          <div className="logo animate__animated animate__lightSpeedInLeft">
            <Link to="/">
              <img src="images/logo.png" alt="" />
            </Link>
          </div>

          <div className="contact flex_space animate__animated   animate__flipInX ">
            {/* <div className="box flex_space">
              <div className="icons">
                <i className="fal fa-clock"></i>
              </div>
              <div className="text">
                <h4>Working Hours</h4>
                <Link to="/contact">Monday - Sunday: 9.00am to 6.00pm</Link>
              </div>
            </div> */}
            <div className="box flex_space ">
              <div className="icons">
                <i className="fas fa-phone-volume"></i>
              </div>
              <div className="text call">
                <h4>Call Us</h4>
                <button className="text call" onClick={handleContactClick}>
                  +91-9622-9622-95
                </button>
              </div>
            </div>
            <div className="box flex_space ">
              <div className="icons">
                <i className="far fa-envelope"></i>
              </div>
              <div className="text ">
                <h4>Mail Us</h4>
                <Link onClick={handleMailClick} to="/contact">
                  booking@theheights.co.in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
