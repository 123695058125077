import React, { useState, useEffect } from "react";
import AboutCard from "../About/AboutCard";

const About = () => {
  const [sectionLoaded, setSectionLoaded] = useState(false);

  useEffect(() => {
    setSectionLoaded(true);
  }, []);

  return (
    <section className={`animate-section ${sectionLoaded ? "animate-section-active" : ""}`}>
      <div className='homeabout'>
        <div className='container top'>
          <AboutCard />
        </div>
      </div>
    </section>
  );
};

export default About;
