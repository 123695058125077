import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sdata from "../Components/Destinations/Sdata";
import EmptyFile from "../Common/Empty/EmptyFile";
import { useParams } from "react-router-dom";
import HeadTitle from "../Common/HeadTitle/HeadTitle";
import "./singlepage.css";
// import AllItem from "../Components/Destinations/AllItem";
import Allhotels from "../Components/Hotels/Allhotels";
import WhatsApp from "../Components/HomeSection/Whatsapp";
import HomePackages from "../Components/Packages/HomePackages";
import Homeadven from "../Components/Adventure/Homeadven";

const SinglePage = () => {
  const { id } = useParams();
  const [item, setItem] = useState(null);

  useEffect(() => {
    let item = Sdata.find((item) => item.id === parseInt(id));
    if (item) {
      setItem(item);
    }
  }, [id]);

  return (
    <>
      <HeadTitle />

      {item ? (
        <section className="single-page top">
          <div className="container  ">
            <Link
              to="/services"
              className="primary-btn back animate__animated animate__lightSpeedInRight "
            >
              <i className="fas fa-long-arrow-alt-left"></i> Go Back
            </Link>

            {/* --------- main-content--------- */}

            <article className="content flex ">
              <div className="main-content animate__animated  animate__fadeInDownBig">
                <img src={item.image} alt="" />
                <p>{item.desc}</p>

                <h1>About {item.title}</h1>
                <p>{item.desc2}</p>

                <div className="image grid1 ">
                  <img src={item.paraImage_one} alt="" />
                </div>
              </div>
              {/* --------- main-content--------- */}

              {/* --------- side-content--------- */}
              <div className="side-content animate__animated animate__fadeInUpBig">
                <div className="box">
                  <h2>How can we help you?</h2>
                  <p>{item.sidepara}</p>

                  <Link to="/contact">
                    <button className="outline-btn">
                      <i className="fa fa-phone-alt"></i> Contact Us
                    </button>
                  </Link>
                </div>

                <div className="box2">
                  <p>{item.sidepara2}</p>
                </div>
              </div>
              {/* --------- side-content--------- */}
            </article>
          </div>

          {/* conditional rendering of components */}
          {id === "1" && <Allhotels />}
          {id === "2" && <HomePackages />}
          {id === "3" && <Homeadven />}

          <WhatsApp />
        </section>
      ) : (
        <EmptyFile />
      )}
    </>
  );
};

export default SinglePage;
