const Tdata = [
  {
    id: 1,
    desc: "Shah Atif, Managing Director of The Heights, is an entrepreneurial and dedicated leader who empowers others. With a decade of experience, a team of 40, and a background in sales and marketing, he has successfully built a thriving company. Inspired by his love for camping, adventure, travelling and photography, Shah Atif started The Heights to provide unique travel experiences. A motivational speaker and compassionate individual, he shares his knowledge and contributes to transformative causes. Shah Atif's exceptional leadership and unwavering commitment continue to inspire and empower others to reach their full potential, embrace their dreams, and achieve remarkable success.",
    name: "Mohammad Atif Shah",
    profile: "/images/profile/atif.jpg",
    post: "Co-Founder / Managing Director",
  },
  {
    id: 2,
    desc: "Mujeeb, MBA in HR and marketing, deftly merged his passion for travel, adventure, and photography, transforming into a thriving entrepreneur. With prior experience as a general manager in renowned hotels, he excelled and specialised in adventure camping, adeptly managing diverse groups. Mujeeb's exceptional skills, job-creating mindset, and expertise made him a standout figure in The Heights' success story. Consistently delivering unparalleled guest experiences, he forged a reputation for excellence in the field, creating a remarkable impact. His unwavering commitment left an indelible mark on the industry as an exceptional entrepreneur.",
    name: "Mujeeb Ahmad",
    profile: "/images/profile/mujeeb.jpg",
    post: "Co-Founder / Chief Marketing Officer",
  },
  {
    id: 3,
    desc: "Shah Tawqeer, civil engineer with strong accounting experience, defied his young age to become a successful entrepreneur. Passionate about people management, he established The Heights and quickly turned it into a thriving business. Shah Tawqeer's business acumen, along with his ability to manage a team and navigate accounting intricacies, has been crucial to his success. Shah Tawqeer's inspiring journey is a testament to the transformative power of determination, strategic thinking, and effective leadership. His remarkable achievements serve as a beacon of hope for aspiring young entrepreneurs, igniting the belief that greatness knows no boundaries.",
    name: "Tawqeer Ahmad Shah",
    profile: "/images/profile/tawqeer.jpg",
    post: "Co-Founder / Chief Financial Officer",
  },

  {
    id: 4,
    desc: "With 20 years of experience in managing people, consulting, travel, and human resourcing, Akeel Ahmad excels as an HR manager. His diverse background and achievements make him a valuable asset to any organisation. His expertise and dedication in his field ensure effective HR management and drive organisational success. Akeel Ahmad's strong problem-solving abilities empower him to tackle challenges effortlessly. Akeel Ahmad's exceptional leadership skills inspire team collaboration and innovation.    ",
    name: "Akeel Ahmad",
    profile: "/images/profile/akeelahmad.jpg",
    post: "HR Manager",
  },

  {
    id: 5,
    desc: "Sofia is an invaluable member of our team, possessing exceptional management skills. As our first team member, she is dedicated and known for her smart work. Sofia efficiently manages the office, ensuring smooth operations. Her commitment and capability make her an indispensable asset, contributing to the overall success of our organization. Sofia's excellent communication skills foster effective collaboration and create a positive work environment.",
    name: "Sofia",
    profile: "/images/profile/sofia.jpg",
    post: "Manager",
  },


  {
    id: 6,
    desc: "Kausar, the dedicated Reservations Manager at The Heights, takes pride in meticulously arranging and organizing each client's travel plans. Her commitment guarantees memorable and stress-free experiences, leaving clients with lasting memories. With a passion for her work, Kausar ensures that every detail is flawlessly executed for an unforgettable travel experience.",
    name: "Kausar",
    profile: "/images/profile/kausar.jpg",
    post: "Reservations Manager",
  },

  {
    id: 7,
    desc: "Sofi Umar is a highly skilled and results-driven accountant with an exceptional ability to meticulously analyze intricate financial data. With his creative and productive mindset, He consistently delivers innovative solutions to even the most complex financial challenges, ensuring utmost accuracy, efficiency, and impressive outcomes. His unwavering dedication and commitment to excellence set him apart.",
    profile: "/images/profile/umer1.jpg",
    name: "Sofi Umar",
    post: "Accounts Manager",
  },
  {
    id: 8,
    desc: "With over 20 years of experience in sales and marketing, Mudasir is a seasoned expert in the field. He has worked with several renowned MNCs, honing his skills and knowledge. Mudasir's extensive background and expertise make him a valuable asset in driving sales growth and developing effective marketing strategies. His proven track record in the industry is a testament to his success and dedication in delivering exceptional results.",
    name: "Mudasir Rehman",
    profile: "/images/profile/mudasir.jpg",
    post: "Sales Manager",
  },
  {
    id: 9,
    desc: "Sehrish possesses remarkable expertise in crafting tour packages, ensuring a seamless experience for guests. Her meticulous attention to detail and problem-solving skills ensure that guests encounter no issues during their tour. Sehrish's ability to curate exceptional packages reflects her commitment to providing unforgettable travel experiences. Her dedication make her an invaluable asset in creating memorable journeys for travelers.",
    name: "Sehrish",
    profile: "/images/profile/sehrish.jpg",
    post: "Tour operator / Reservations Manager",
  },

  {
    id: 10,
    desc: "Adina is a talented graphic designer with three years of experience in the field. She possesses a unique ability to transform imagination into reality through her artistic skills. Dedicated and committed to her work, Adina consistently delivers high-quality designs that bring visions to life. Her keen eye for detail, leaving a lasting impression on clients and colleagues alike.",
    name: "Adina",
    profile: "/images/profile/female.jpg",
    post: "Graphic Designer",
  },
  {
    id: 11,
    desc: "Haris Malk is the UI/UX Developer at The Heights, responsible for creating seamless and user-friendly digital experiences for our clients. With a keen eye for design and a passion for technology, Haris ensures that our website and digital platforms are easy to navigate and visually appealing.",
    name: "Haris Malik",
    profile: "/images/profile/haris.jpg",
    post: "UI/UX Developer",
  },
];

export default Tdata;
