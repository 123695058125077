import React, { useState } from "react";
import "../Packages/pack.css";
const Gangbal = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPack, setShowPack] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
    setIsClicked(!isClicked);
  };

  const winterPack = () => {
    setShowPack(!showPack);
    setIsClicked(!isClicked);
  };

  return (
    <div className="packages">
      {isClicked ? (
        <h1 onClick={handleClick}>
          Gangabal Lake Trek
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </h1>
      ) : (
        <h1 onClick={handleClick}>
          Gangabal Lake Trek
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </h1>
      )}
      {showDetails && (
        <div>
          <div className="images">
            <img src="/images/gangabal-lake-trek.jpg" alt="" />
          </div>

          <h2>Inclusions Whats' included</h2>
          <ul>
            <li>Accomodation</li>
            <li>Transferr</li>
            <li>Meals</li>
            <li>Sightseeing</li>
          </ul>

          {isClicked ? (
            <h3 onClick={winterPack}>
              Gangabal Lake Trek (Detailed Itinerary)
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </h3>
          ) : (
            <h3 onClick={winterPack}>
              Gangabal Lake Trek (Detailed Itinerary)
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </h3>
          )}
          {showPack && (
            <>
              <h3>Day - 1 Arrival </h3>
              <h4>Day Description</h4>
              <p>
                You will be met on your arrival by our representative and
                transferred to the houseboat, where your trek leader will give
                you the predeparture briefing. There is a short afternoon
                Shikara ride of Dal/Nigeen Lake. Overnight at houseboat. (D)
              </p>

              <h3>Day - 2 Srinagar - Sonamarg </h3>
              <h4>Day Description</h4>
              <p>
                We drive through the picturesque Sindh valley, along the
                SindhRiver, to the highland of Sonamarg, known as the ‘meadow of
                Gold’ as it is believed the water upon the vale has mystical
                wealth inducing properties. After lunch we take a side walk to
                Thajwas glacier. Overnight at Sonamarg in tents on a two person
                share basis. (BLD)
              </p>

              <h3>Day - 3 Sonmarg - Nichinai</h3>
              <h4>Day Description</h4>
              <p>
                A short descent through the nearby pine forestbrings us to a
                steel bridge over the Sindh river. From here we climb steeply to
                Shkoder, a great vantage point to view the glacier and the Sindh
                valley.An undulating descentbrings us to our overnight camp at
                Nichinai. (BLD)
              </p>

              <h3>Day - 4 Nichinai - Krishansar</h3>
              <h4>Day Description</h4>
              <p>
                Our trek begins with an ascent to Nichinai Pass(4084m) to enjoy
                the spectacular mountain views. We then descend through flower
                strewn meadows towards Vishansar and Krishansar lakes. We cross
                a small stream to reach our camp at Vishansar lake, where we
                will stay for two nights. (BLD)
              </p>

              <h3>Day - 5 Krishansar - Dubta Pani </h3>
              <h4>Day Description</h4>
              <p>
                Today our trek begins with a steep ascent toward Gadsar pass,
                which offers a fantastic view of the Karakorum Mountains. A
                gradual descent along the contour and past Gadsar lake will
                bring us to our overnight camp beside a stream – a welcome
                temptation for an afternoon swim on a sunny day.(BLD)
              </p>

              <h3>Day - 6 Dubta Pani - Gangabal Lakes </h3>
              <h4>Day Description</h4>
              <p>
                After crossing the steam we climb gradually to Postpatri (the
                valley of flowers)for another spectacular view of the Karakoram
                mountains. When we reach the saddle at Mangandub we climb
                steeply towards Zagibal peak (4210m). The climb is strenuous but
                well worth the effort as we are rewarded with unrivalledviews of
                the Gangabal lakes and Hurmukh peak. A long zig zag descent
                brings us to the stream crossing, followed by a short climb to
                our two night camp at Gangabal lakes. (BLD)
              </p>

              <h3>Day - 7 Gangabal Lake (3570m). Rest Day </h3>
              <h4>Day Description</h4>
              <p>
                Today is a well deserved rest day. You can enjoy a pretty walk
                around the lake, rainbow trout fishing or, if you have a little
                more energy, head up to Lul Gul pass for a breatkaing view of
                Nangapabath.(BLD)
              </p>

              <h3>Day - 8 Gangbal Lake - Srinagar (1585m) 1 hour drive </h3>
              <h4>Day Description</h4>
              <p>
                After a leisurely breakfast our trail leads us past the two
                small villages of Nunkol and Trunkol, followed by a steep
                descent through a beautiful dense pine forest to Naranag. Here
                we visit a Buddhist monastery, where the 3rd world conference of
                Buddhists was held.This will be our finishing point of Gangabal
                Lake trek .We will be transferred by a chartered coach/car toa
                houseboat for our overnight stay.(BLD)
              </p>

              <h3>Day 9- Depart Srinagar </h3>
              <h4>Day Description</h4>
              <p>You will be transferred to the airport.</p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Gangbal;
