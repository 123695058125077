import React, { useState } from "react";
import "./pack.css";
const Summar = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPack, setShowPack] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
    setIsClicked(!isClicked);
  };

  const winterPack = () => {
    setShowPack(!showPack);
    setIsClicked(!isClicked);
  };

  return (
    <div className="packages">
      {isClicked ? (
        <h1 onClick={handleClick}>
          Tulip Season Package  (3N 4D)
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </h1>
      ) : (
        <h1 onClick={handleClick}>
          Tulip Season Package  (3N 4D)
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </h1>
      )}
      {showDetails && (
        <div>
          <h2>Inclusions </h2>
          <ul>
            <li>Accomodation</li>
            <li>Breakfast & Dinner</li>
            <li>Day trip to Pahalgam</li>
            <li>Day trip to Gulmarg</li>
            <li>Srinagar Local Sightseeing</li>
            <li>Shikara Ride for 01 Hour</li>
            <li>Pick up & Drop at Srinagar Airport</li>
          </ul>
          <h2>Exclusions </h2>
          <ul>
            <li>Air Tickets / Train Tickets</li>
            <li>Pony Ride / Sledging</li>
            <li>Entry fee at all places & gardens</li>
            <li>Local Cabs for Sightseeing (Sonmarg, Gulmarg, Pahalgam)</li>
            <li>Gandola Ride Tickets</li>
          </ul>
          {isClicked ? (
            <h3  id="packdet" onClick={winterPack}>
              Package Details
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </h3>
          ) : (
            <h3  id="packdet" onClick={winterPack}>
              Package Details
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </h3>
          )}
          {showPack && (
            <>
              <h3>Day 1: Arrival at Srinagar Airport</h3>
              <h4>Day Description</h4>
              <p>
                Welcome to Srinagar, Kashmir! Upon your arrival at Srinagar
                Airport, our representative will be there to pick you up. From
                the airport, you will be transferred to your hotel. Take some
                time to relax and unwind. Later, proceed to visit the
                world-famous Mughal Gardens, including Nishat Garden, Shalimar
                Garden, and Tulip Garden. These gardens are renowned for their
                stunning beauty, Mughal architecture, and colorful blooms. After
                the garden tour, return to your hotel for a delicious dinner and
                overnight stay in Srinagar.{" "}
              </p>
              <h3>Day 2: Srinagar - Pahalgam - Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                After enjoying a scrumptious breakfast at the hotel, embark on a
                day trip to Pahalgam Valley, also known as the "Valley of
                Shepherds." Pahalgam, situated at an altitude of 2440 meters
                above sea level, is a popular destination for the Indian film
                industry. En route, visit the saffron fields and immerse
                yourself in the natural charm of the valley. You can indulge in
                various sporting activities and explore attractions like Betaab
                Valley, Chandanwari, and Aru Valley (additional charges may
                apply). After a day of sightseeing, return to your hotel in
                Srinagar for dinner and a comfortable overnight stay.{" "}
              </p>
              <h3>Day 3: Srinagar - Gulmarg - Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                After a delightful breakfast, embark on a day trip to Gulmarg,
                known as the "Meadow of Flowers." Located at an altitude of
                approximately 2730 meters above sea level, Gulmarg is a
                beautiful hill station and a popular skiing destination in
                winter. The highlight of Gulmarg is the "Gulmarg Gondola," one
                of the highest cable cars in the world (additional charges
                apply). Enjoy the breathtaking beauty of the town and engage in
                various activities. After a day of sightseeing, return to your
                hotel in Srinagar for dinner and a comfortable overnight stay.{" "}
              </p>
              <h3>Day 4: Departure from Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                After breakfast, check out from your hotel and proceed to
                Srinagar Airport for your onward journey. Bid farewell to the
                enchanting beauty of Srinagar and take unforgettable memories
                back home.{" "}
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Summar;
