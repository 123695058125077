import React from "react";
import Card from "./Card";
import Tdata from "./Tdata";
import "../Testimonial/Testimonial.css";

const AllItem = () => {
  const founders = Tdata.slice(0, 3); // Extract the first three items
  const teamMembers = Tdata.slice(3); // Extract the rest of the items

  return (
    <>
      <section className="Testimonial mtop">
        <h1 className="teamhead">Founders</h1>
        <div className="container grid1">
          {founders.map((value, index) => (
            <Card key={index} {...value} />
          ))}
        </div>
        <h1 className="teamhead">Our Team</h1>
        <div className="container grid1">
          {teamMembers.map((value, index) => (
            <Card key={index} {...value} />
          ))}
        </div>
      </section>
    </>
  );
};

export default AllItem;
