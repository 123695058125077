import React from "react"
import "./About.css"
import AboutCard from "./AboutCard"
import HeadTitle from "../../Common/HeadTitle/HeadTitle"
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <HeadTitle />

      <section className='about top'>
        <div className='container'>
          <AboutCard />
        </div>
      </section>

      <section className='features top'>
        <div className='container aboutCard flex_space '>
          <div className='row row1 animate__animated  animate__zoomInRight'>
            <h1>
              Our <span>Features</span>
            </h1>
            <p>At The Heights, we're dedicated to providing you with an exceptional travel experience. Our team of experts curate the best travel packages in Kashmir, ensuring you get the most out of your trip.</p>
            <p>Whether you're looking for adventure, relaxation or a bit of both, we've got you covered.
               From luxurious accommodations to exciting excursions, our packages are designed to meet your needs and exceed your expectations.</p>
            <Link to="/contact" className='secondary-btn'>
              Contact us <i className='fas fa-long-arrow-alt-right'></i>
            </Link>
          </div>
          <div className='row image animate__animated  animate__zoomInLeft'>
            <img src='/images/slider-1.jpg' alt='' />
            <div className='control-btn'>
              <button className='prev'>
                <i className='fas fa-play'></i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
