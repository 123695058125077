/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import Data from "./Data";
import "./Slide.css";

const Home = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [current]);

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <>
      <section className="slider">
        <div className="control-btn">
          <button
            className="prev"
            onClick={prevSlide}
            aria-label="Previous Slide"
          >
            <i className="fas fa-caret-left"></i>
          </button>
          <button className="next" onClick={nextSlide} aria-label="Next Slide">
            <i className="fas fa-caret-right"></i>
          </button>
        </div>

        {Data.map((slide, index) => {
          const overlayText =
            index === 0
              ? "The Heights"
              : index === 1
              ? " Hotel Shanu  "
              : index === 2
              ? "Hotel Fifth Season "
              : index === 3
              ? "WaterFront Pine "
              : index === 4
              ? "Hotel Hills Heaven "
              : index === 5
              ? "Shabnam Resort "
              : index === 6
              ? "Kashmir Holiday Inn "
              : "Adventure Awaits, Discover Your Thrill";

          const overlaypara =
            index === 0
              ? ""
              : index === 1
              ? "Gulmarg"
              : index === 2
              ? "Pahalgam"
              : index === 3
              ? "Pahalgam"
              : index === 4
              ? "Pahalgam"
              : index === 5
              ? "Pahalgam"
              : index === 6
              ? "Srinagar"
              : "Adventure Awaits, Discover Your Thrill";
          return (
            <div
              className={index === current ? "slide active" : "slide"}
              key={index}
            >
              {index === current && (
                <div className="slide-container">
                  <img
                    className="slide-img"
                    src={slide.image}
                    alt="Home Image"
                  />
                  <div className="slide-text">{overlayText}</div>
                  <div className="slide-text-para">{overlaypara}</div>
                </div>
              )}
            </div>
          );
        })}
      </section>
    </>
  );
};

export default Home;
