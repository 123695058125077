import React, { useState } from 'react';
import data from "./data"
import "./gallery.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Gallery = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item)
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 4, // Display 4 slides at a time on larger devices
    slidesToScroll: 2, // Scroll 2 slides at a time on larger devices
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set the autoplay interval to 3 seconds
    pauseOnHover: false, // Prevent the slider from pausing on hover
    responsive: [
      {
        breakpoint: 768, // Apply these settings for devices with a width of 768px or less
        settings: {
          slidesToShow: 2, // Display 2 slides at a time on smaller devices
          slidesToScroll: 1, // Scroll 1 slide at a time on smaller devices
        },
      },
      {
        breakpoint: 480, // Apply these settings for devices with a width of 480px or less
        settings: {
          slidesToShow: 1, // Display 1 slide at a time on extra small devices
          slidesToScroll: 1, // Scroll 1 slide at a time on extra small devices
        },
      },
    ],
  }
  return (
    <div>
      <section className='collection'>
        <div className='container top'>
          <Slider {...settings}>
            {data.map((value) => {
              return (
                <div className={`box ${selectedItem === value ? 'selected' : ''}`} onClick={() => handleItemClick(value)}>
                  <img src={value.cover} alt='' />
                </div>
              )
            })}
          </Slider>
        </div>
      </section>
    </div>
  )
}

export default Gallery
