import "./App.css";
import Navbar from "./Common/Navbar/Navbar";
import Home from "./Components/pages/Home";
import About from "./Components/About/About";
//import Destinations from "./Components/Destinations/Destinations"
//import DHome from "./Components/Destinations/Home"
import Destinations from "./Components/Destinations/Home";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SinglePage from "./SinglePage/SinglePage";


/*-------------blog------------ */
import Testimonial from "./Components/Testimonial/Testimonial";
import Team from "./Components/Team/Team";
import Contact from "./Components/Contact/Contact";
import Footer from "./Common/footer/Footer";
/*-------------blog------------ */

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/abouttheheights"  exact component={About} />
          <Route path="/services" exact component={Destinations} />
          <Route path="/singlepage/:id" component={SinglePage} />

          <Route path="/testimonial" component={Testimonial} />
          <Route path="/team" component={Team} />
          <Route path="/contact" component={Contact} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
