import React from "react";
import "./pack.css";
import Winter from "./Winter";
import Summar from "./Summar";
import Honeymoon from "./Honeymoon";
import Autumn from "./Autumn";
import Tulip from "./Tulip";
import Custom from "./Custom";

const HomePackages = () => {
  return (
    <>
      <div className="packHome">
        <h1 id="pack">Package</h1>
        <Winter />
        <Summar />
        <Honeymoon />
        <Autumn />
        <Tulip />
        <Custom />
      </div>
    </>
  );
};
export default HomePackages;
