import React, { useState } from "react"
import HCards from "./HCards"
import "../gallery/Gallery.css"
import "../Destinations/Destinations.css"
import Hdata from "./Hdata"

const Allhotels = () => {
  const [items, setItems] = useState(Hdata)

  return (
    <section className='gallery desi mtop'>
      <div className='container htp'>
        <div className='content grid htpcard'>
          {items.map((item) => {
            return <HCards key={item.id} item={item} website={item.website} />
          })}
        </div>
      </div>
    </section>
  )
}

export default Allhotels
