const Wdata = [
  {
    id: 1,
    cover: "/images/works/work-3.png",
    title: "As a Hotel",
    desc: "At The Heights, we believe that partnerships are essential to success. By partnering with us, you'll have access to our dedicated team of professionals who are committed to providing exceptional service and experiences to all our guests. Partnering with us means you'll be working with a trusted brand that is committed to providing the best service and experiences to our guests. Contact us today to learn more about how we can help you grow your business.",
  },
  {
    id: 2,
    cover: "/images/works/work-1.png",
    title: "As a Tour and Travel ",
    desc: "When you partner with us, you gain access to a wide range of diverse itineraries, competitive pricing, and expert guidance. Our team specializes in creating personalized options tailored to your needs, allowing you to differentiate yourself and boost revenue. With the support of our experienced professionals, your clients will be guided through the complexities of the travel world, ensuring a seamless and unforgettable experience. As your reliable ally in the travel industry, we are committed to supporting your business and delivering exceptional travel solutions that leave lasting memories.",
  },
  {
    id: 3,
    cover: "/images/works/work-2.png",
    title: "As an Adventure ",
    desc: "Partnering with us opens the door to unique and thrilling experiences, along with competitive pricing, a strong emphasis on safety, and expert guidance. Our dedicated team specializes in creating customized experiences tailored to your specific needs, ensuring both excitement and safety go hand in hand. By collaborating with a trusted brand committed to delivering exceptional service, you can be confident in providing unforgettable and thrilling adventures to your clients.",
  },
];
export default Wdata;
