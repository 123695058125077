import React from "react";

const Card = (props) => {
  const handleRedirect = () => {
    window.open(
      "https://www.google.com/maps/place/The+Heights/@34.0432891,74.8153573,17z/data=!4m8!3m7!1s0x38e18fe081a138ab:0x923df609afff636!8m2!3d34.0432891!4d74.8179322!9m1!1b1!16s%2Fg%2F11hfhgf53n?hl=en-US&entry=ttu",
      "_blank"
    );
  };

  return (
    <>
      <div className="box animate__animated  animate__jackInTheBox">
        <i className="fa fa-quote-left"></i>
        <div className="para">
          <p onClick={handleRedirect}>{props.desc}</p>
        </div>
        <hr />
        <div className="details flex">
          <div className="img" onClick={handleRedirect}>
            <img src={props.profile} alt="" />
          </div>
          <div className="name" onClick={handleRedirect}>
            <h3>{props.name}</h3>
            <span>{props.post}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
