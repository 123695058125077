const data = [
  {
    cover: "./images/gallery/gallery-1.png",
  },
  {
    cover: "./images/gallery/gallery-2.png",
  },
  {
    cover: "./images/gallery/gallery-3.png",
  },
  {
    cover: "./images/gallery/gallery-4.png",
  },
  {
    cover: "./images/gallery/gallery-5.png",
  },
 
  {
    cover: "./images/gallery/gallery-7.png",
  },
  {
    cover: "./images/gallery/gallery-8.png",
  },
  {
    cover: "./images/gallery/gallery-9.png",
  },
  {
    cover: "./images/gallery/gallery-10.png",
  },
  {
    cover: "./images/gallery/gallery-11.png",
  },
];

export default data;
