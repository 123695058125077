import React from "react";
import HeadTitle from "../../Common/HeadTitle/HeadTitle";
import AllItem from "./AllItem";
import WhatsApp from "../HomeSection/Whatsapp";

const Testimonial = () => {
  return (
    <>
      <HeadTitle />
      <AllItem />
      <WhatsApp />
    </>
  );
};

export default Testimonial;
