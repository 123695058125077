import React, { useState } from "react";
import "../Packages/pack.css";
const Tarsar = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPack, setShowPack] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
    setIsClicked(!isClicked);
  };

  const winterPack = () => {
    setShowPack(!showPack);
    setIsClicked(!isClicked);
  };

  return (
    <div className="packages">
      {isClicked ? (
        <h1 onClick={handleClick}>
          Pahalgam-Tarsar-Marsar Trek, Kashmir
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </h1>
      ) : (
        <h1 onClick={handleClick}>
          Pahalgam-Tarsar-Marsar Trek, Kashmir
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </h1>
      )}
      {showDetails && (
        <div>
          <div className="images">
            <img src="/images/pahalgam-tarsar-marsar-trek.jpg" alt="" />
          </div>

          <h2>Inclusions Whats' included</h2>
          <ul>
            <li>Accomodation</li>
            <li>Transferr</li>
            <li>Meals</li>
            <li>Sightseeing</li>
          </ul>

          {isClicked ? (
            <h3 onClick={winterPack}>
              Pahalgam Tarsar Marsar Trek (Detailed Itinerary)
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </h3>
          ) : (
            <h3 onClick={winterPack}>
              Pahalgam Tarsar Marsar Trek (Detailed Itinerary)
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </h3>
          )}
          {showPack && (
            <>
              <h3>Day - 1 Arrival </h3>
              <h4>Day Description</h4>
              <p>
                On arrival at Srinagar, meet our representative at the airport
                and transfer to your houseboat. Overnight stay at the houseboat.
              </p>

              <h3>Day - 2 Srinagar - Pahalgam - Aru </h3>
              <h4>Day Description</h4>
              <p>
                Today, we shall drive to Pahalgam, which is 90 kms from
                Srinagar. From Pahalgam, we shall drive to Aru valley and stay
                overnight here.
              </p>

              <h3>Day - 3 Aru – Lidderwat</h3>
              <h4>Day Description</h4>
              <p>
                We shall start trekking after the breakfast. The trek will be
                from Aru Valley to Lidderwat via pine forests and lush meadows
                along the stream known as Right Lidder River. Overnight stay at
                Lidderwat.
              </p>

              <h3>Day - 4 Lidderwat - Tarsarr</h3>
              <h4>Day Description</h4>
              <p>
                Early in the morning, we shall trek to Tarsar Lake and en route
                shall cross a pass of 4000 mtrs continuing trekking under the
                towering heights of Kolahoi Mountain to finally reach Tarsar
                Lake. Tarsar is an almond shaped crystal clear lake that is
                surrounded by variety of alpine. Overnight stay in the camps at
                Tarsar.
              </p>

              <h3>Day - 5 Tarsar - Marsar</h3>
              <h4>Day Description</h4>
              <p>
                Today, we will trek towards the sister lake of Tarsar that lies
                on the opposite side of the mountain on the west. A steep ascend
                taking about 3 hours and a gradual descend leads to another
                stunning lake called Marsar. Overnight stay in the tents at
                Marsar.
              </p>

              <h3>Day - 6 Marsar - Sumbal </h3>
              <h4>Day Description</h4>
              <p>
                We will be heading to Sumbal today. A steady climb to a mountain
                pass (4100m) towards north and a gradual descend will bring us
                to Sumbal in the Sind valley. Overnight Stay in Sumbal.
              </p>

              <h3>Day - 7 Sumbal - Srinagar </h3>
              <h4>Day Description</h4>
              <p>
                We shall drive through Sind Valley to reach Srinagar, which will
                take around 3 hours. Overnight stay at the houseboat.
              </p>

              <h3>Day - 8 Departure day </h3>
              <h4>Day Description</h4>
              <p>
                Our representative shall assist you to the airport. If there are
                few hours to spare (depending upon the time of the flight), we
                can organize a sightseeing trip in Srinagar.
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Tarsar;
