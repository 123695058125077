import React from "react";
import hdata from "../Hotels/Hdata";
import { Link } from "react-router-dom";

const Cards = ({
  item: { id, image, title, sidepara, desc, paraImage_one, paraImage_two },
}) => {
  // const handleClick = () => {
  //   if (id === 2) {
  //     window.open("https://theheights.co.in/", "_blank");
  //   } else {
  //     window.scrollTo(0, 0);
  //   }
  // };
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  }
  return (
    <Link to={`/singlepage/${id}`} onClick={handleLinkClick} >
      <div className="items animate__animated  animate__flipInY">
        <div className="img">
          <img src={image} alt="" />
          <Link to={`/singlepage/${id}`} className="blogItem-link">
            <i className="fas fa-external-link-alt"></i>
          </Link>
        </div>

        <div className="title">
          <h3>{title}</h3>
        </div>
      </div>
    </Link>
  );
};

export default Cards;
