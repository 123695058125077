import React, { useState } from "react";
import "./pack.css";
const Summar = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [showPack, setShowPack] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
    setIsClicked(!isClicked);
  };

  const winterPack = () => {
    setShowPack(!showPack);
    setIsClicked(!isClicked);
  };

  return (
    <div className="packages">
      {isClicked ? (
        <h1 onClick={handleClick}>
          Honeymoon Package (5N 6D)
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </h1>
      ) : (
        <h1 onClick={handleClick}>
          Honeymoon Package (5N 6D)
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </h1>
      )}
      {showDetails && (
        <div>
          <h2>Inclusions </h2>
          <ul>
            <li>Accomodation</li>
            <li>Breakfast & Dinner</li>
            <li>Srinagar Local Sightseeing</li>
            <li>Shikara Ride for 01 Hour</li>
            <li>Pick up & Drop at Srinagar Airport</li>
          </ul>
          <h2>Exclusions </h2>
          <ul>
            <li>Air Tickets / Train Tickets</li>
            <li>Pony Ride / Sledging</li>
            <li>Entry fee at all places & gardens</li>
            <li>Local Cabs for Sightseeing (Sonmarg, Gulmarg, Pahalgam)</li>
            <li>Gandola Ride Tickets</li>
            <li>Shopping</li>
          </ul>
          {isClicked ? (
            <h3 id="packdet" onClick={winterPack}>
              Package Details
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </h3>
          ) : (
            <h3 id="packdet" onClick={winterPack}>
              Package Details
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </h3>
          )}
          {showPack && (
            <>
              <h3>Day 1: Arrival in Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                Welcome to Srinagar, Kashmir ! Upon your arrival at Srinagar
                Airport, our representative will be there to greet you. You will
                then be transferred to your hotel, where you can check in and
                take some time to relax. In the afternoon, embark on a visit to
                the world-famous Mughal Gardens, including Nishat Garden,
                Shalimar Garden, and Chashme Shahi. These gardens are renowned
                for their stunning beauty, featuring manicured lawns, vibrant
                flowers, and serene fountains. After the garden tour, return to
                your hotel and savor a delicious dinner. Enjoy an overnight stay
                at your hotel in Srinagar.{" "}
              </p>
              <h3>Day 2: Srinagar - Sonamarg - Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                After breakfast, embark on a full-day excursion to Sonamarg,
                also known as the "Meadow of Gold." Experience the breathtaking
                beauty of the scenic roads lined with pine trees and green
                meadows. Along the way, marvel at the Nilagrad River and Sind
                River. At Sonamarg, you have the option to take a pony ride (at
                your own expense) to the enchanting Thajiwas glacier, where you
                can witness snow all year round. In the evening, return to your
                hotel in Srinagar for an overnight stay.{" "}
              </p>
              <h3>Day 3: Srinagar - Gulmarg</h3>
              <h4>Day Description</h4>
              <p>
                After a satisfying breakfast, embark on a full-day excursion
                from Srinagar to Gulmarg, with an enchanting stopover at
                Doodhpathri, known as the "Valley of Milk." Enjoy the scenic
                drive through roads lined with pine trees and green meadows,
                immersing yourself in the picturesque beauty. Doodhpathri,
                located around 40 kilometers from Srinagar, offers a serene and
                tranquil atmosphere, boasting untouched natural beauty. Spend a
                delightful day amidst the pristine surroundings, taking
                leisurely walks, capturing breathtaking views, and relishing the
                soothing ambiance. In the evening, return to your Srinagar hotel
                for dinner and an overnight stay, carrying cherished memories of
                Doodhpathri's enchanting charm.
              </p>
              <h3>Day 4: Gulmarg - Pahalgam</h3>
              <h4>Day Description</h4>
              <p>
                After an early breakfast, prepare for a road trip from Gulmarg
                to Pahalgam. Keep your luggage packed and embark on the journey
                to Pahalgam, also known as the "Valley of Shepherds." Pahalgam,
                situated at an altitude of 2440 meters above sea level, is
                famous for its natural beauty and is a popular location for the
                Indian film industry. En route, visit the beautiful saffron
                fields. Upon reaching Pahalgam, you can enjoy various sporting
                activities and explore attractions such as Betaab Valley,
                Chandanwari, and Aru Valley (additional charges may apply).
                After sightseeing, return to your hotel in Pahalgam for an
                overnight stay.{" "}
              </p>
              <h3>Day 5: Pahalgam - Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                After breakfast, bid farewell to Pahalgam and embark on a scenic
                road trip back to Srinagar. Upon arrival, complete the check-in
                process at your hotel. In the afternoon, enjoy a serene shikara
                ride on the beautiful Dal Lake. Experience the tranquility of
                the lake and take in the stunning surroundings. In the evening,
                return to your houseboat for a romantic candlelight dinner and
                spend the night there.
              </p>
              <h3>Day 6: Departure from Srinagar</h3>
              <h4>Day Description</h4>
              <p>
                After a hearty breakfast at your hotel, it's time to conclude
                your Kashmir tour. Our representative will accompany you to
                Srinagar Railway Station or Airport for your departure. Bid
                farewell to the enchanting land of Kashmir and embark on your
                journey back home, carrying beautiful memories with you.{" "}
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Summar;
