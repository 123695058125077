import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const Footer = () => {
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 750,
      smooth: "easeInOutQuart",
    });
  };
  const handleContactClick = () => {
    const phoneNumber = " +919622962295";
    const href = `tel:${phoneNumber}`;
    window.location.href = href;
  };
  const handleMailClick = () => {
    const emailAddress = "booking@theheights.co.in";
    const href = `mailto:${emailAddress}`;
    window.location.href = href;
  };
  return (
    <>
      <footer>
        <div className="container grid">
          <div className="box">
            <h2>ABOUT US</h2>
            <p>
              At The Heights, we are committed to responsible and sustainable
              tourism practices. We understand the impact that travel can have
              on the environment and local communities, which is why we strive
              to minimize our ecological footprint and contribute to the
              well-being of the regions we visit. We work closely with local
              communities and organizations to support conservation efforts,
              promote cultural heritage, and provide economic opportunities. By
              traveling with us, you can be sure that your experience is not
              only enjoyable but also ethical and sustainable.
            </p>

            <div className="icon">
              <i
                className="fab fa-facebook-f"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/theheights.co.in",
                    "_blank"
                  )
                }
              ></i>
              <i
                className="fab fa-twitter"
                onClick={() =>
                  window.open("https://twitter.com/team_theheights", "_blank")
                }
              ></i>
              <i
                className="fab fa-instagram"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/https://www.instagram.com/official_theheights/",
                    "_blank"
                  )
                }
              ></i>

              {/* <i className='fab fa-pinterest'></i> */}
              <i
                className="fab fa-youtube"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCGKr7AauH57ZS-4Qqsdq9VQ",
                    "_blank"
                  )
                }
              ></i>
              <i
                className="fa fa-link"
                onClick={() =>
                  window.open(
                    "https://www.google.com/maps/place//data=!4m3!3m2!1s0x38e18fe081a138ab:0x923df609afff636!12e1?source=g.page.m.nr._&laa=nmx-review-solicitation-recommendation-card",
                    "_blank"
                  )
                }
              ></i>
            </div>
          </div>
        
          <div className="box">
            <h2>NAVIGATION</h2>
            <ul>
              <li>
                <Link to="/" onClick={scrollToTop}>
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/abouttheheights"
                  aria-label="Learn more about The Heights"
                  onClick={scrollToTop}
                >
                  About us
                </Link>
              </li>

              <li>
                <Link to="/services" onClick={scrollToTop}>
                  Services
                </Link>
              </li>

              <li>
                <Link to="/testimonial" onClick={scrollToTop}>
                  Testimonial
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={scrollToTop}>
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/team" onClick={scrollToTop}>
                  Team
                </Link>
              </li>
              <li>
              <a href="https://renderrush.digital.vistaprint.io/s/rFr7xTTlgatZ4oyHWXIjK" >Business Card</a>
              </li>
              <li>
                <p onClick={handleContactClick}> +91-9622-9622-95</p>
              </li>
              <li>
                <p onClick={handleMailClick}>booking@theheights.co.in</p>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="legal">
        <p>© 2024 All Rights Reserved.</p>
      </div>
    </>
  );
};

export default Footer;
