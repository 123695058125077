import React, { useState } from "react";
import "./Contact.css";
import axios from "axios";

const ContactForm = () => {
  const [fname, setFname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name: fname,
      phone: phone,
      email: email,
      message: message,
    };

    try {
      const response = await axios.post(
        "https://formspree.io/f/xrgvwgkn",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
      alert("Your message has been sent!");
    } catch (error) {
      console.log(error);
      alert("Something went wrong. Please try again later.");
    }

    setFname("");
    setPhone("");
    setEmail("");
    setMessage("");
  };
  return (
    <>
      <section className="contact mtop">
        <div className="container flex animate__animated animate__lightSpeedInRight">
          <div className="main-content">
            <h2>Contact Form</h2>
            <p>Fill out the form below and we will get back to you soon.</p>

            <form onSubmit={handleSubmit}>
              <div className="grid1">
                <div className="input">
                  <span>
                    Your Name <label>*</label>
                  </span>
                  <input
                    type="text"
                    name="fname"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    required
                  />
                </div>

                <div className="input">
                  <span>
                    Phone Number <label>*</label>
                  </span>
                  <input
                    type="text"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="input">
                  <span>
                    Email <label>*</label>
                  </span>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="input">
                <span>Message</span>
                <textarea
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows="5"
                ></textarea>
              </div>
              <button type="submit" className="btn primary-btn">
                Send
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
